import * as React from "react"
import Layout from "../components/layout"

const IndexPage = props => {
  console.log(props)
  const { pageContext } = props
  return (
    <Layout>
      <div class="overflow-x-hidden bg-gradient-to-b from-blue-700">
        <div class="px-6 py-8 ">
          <div class="container flex justify-between mx-auto">
            <div class="container overflow-x-hidden ">
              <h1 class="text-xl text-white text-center  p-4 justify-center text-5xl	">
                {pageContext.title} Ministry
              </h1>
              <div class="px-0 py-8">
                <section
                  sx={{ pb: 4 }}
                  dangerouslySetInnerHTML={{ __html: pageContext.html }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
